import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import P from '@deliveroo/consumer-component-library/components/P';
import LoadingIndicator from '@deliveroo/consumer-component-library/components/LoadingIndicator';

import CenteredContainer from '../CenteredContainer';
import JobRow from '../JobRow';
import SectionHeading from '../SectionHeading';
import styles from './styles.module.scss';

const sortByKey = (key) => (a, b) => {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
};

// Departments:
// Experience (Parent): 217557
// Consumer Insights: 217641

function getJobs() {
  const departments = [
    217646,
    217647,
    217648,
    217649,
    217650,
    217651,
    217652,
    217653,
    217654,
    217656,
    217655,
    217657,
    217658,
    217641,
  ];

  const fetchJobLists = departments.map((id) =>
    fetch(`https://api.greenhouse.io/v1/boards/deliveroo/departments/${id}`)
      .then((res) => res.json())
      .then(({ jobs }) => jobs),
  );

  return Promise.all(fetchJobLists).then((jobLists) =>
    jobLists.reduce(
      (jobs, jobList) => [
        ...jobs,
        ...jobList.map((job) => ({
          id: job.id,
          link: job.absolute_url,
          role: job.title,
          location: job.location.name,
        })),
      ],
      [],
    ),
  );
}

class JobsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      didError: false,
      jobs: [],
    };
  }

  componentDidMount() {
    getJobs()
      .then((jobs) => {
        this.setState({
          isLoading: false,
          didError: false,
          jobs: jobs.sort(sortByKey('role')),
        });
      })
      .catch((err) => {
        console.error(err); // eslint-disable-line no-console
        this.setState({
          isLoading: false,
          didError: true,
        });
      });
  }

  renderJobsList() {
    if (this.state.isLoading) {
      return <LoadingIndicator size={24} />;
    }

    if (this.state.didError) {
      return (
        <P color="grey">
          {
            "We couldn't load our job listings. Refresh the page or come back later."
          }
        </P>
      );
    }

    return (
      <ul className={styles.listWrapper}>
        {this.state.jobs.map(({ id, ...props }) => (
          <li key={id} className={styles.rowWrapper}>
            <JobRow {...props} />
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <CenteredContainer>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <SectionHeading
              title={this.props.title}
              markdown={this.props.markdown}
            />
          </div>
          <div
            className={classnames(styles.jobs, {
              [styles.isLoading]: this.state.isLoading,
              [styles.didError]: this.state.didError,
            })}
          >
            {this.renderJobsList()}
          </div>
        </div>
      </CenteredContainer>
    );
  }
}

JobsSection.propTypes = {
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
};

export default JobsSection;
