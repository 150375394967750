import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Heading from '@deliveroo/consumer-component-library/components/Heading';
import BackgroundImage from '@deliveroo/consumer-component-library/components/BackgroundImage';
import CenteredContainer from '../CenteredContainer';
import styles from './styles.module.scss';

const TeamRow = ({ image, title, markdown, isFlipped, hasMargin }) => (
  <CenteredContainer>
    <div
      className={classnames(
        styles.team,
        isFlipped && styles.isFlipped,
        hasMargin && styles.hasMargin,
      )}
    >
      <div className={styles.image}>
        <BackgroundImage src={image} />
      </div>
      <div className={styles.content}>
        <Heading level={3} size="medium" spacing="0 0 medium 0">
          {title}
        </Heading>
        <div
          className={styles.markdown}
          dangerouslySetInnerHTML={{ __html: markdown }}
        />
      </div>
    </div>
  </CenteredContainer>
);

TeamRow.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  hasMargin: PropTypes.bool.isRequired,
};

export default TeamRow;
