import React from 'react';
import PropTypes from 'prop-types';

import Heading from '@deliveroo/consumer-component-library/components/Heading';
import styles from './styles.module.scss';

const SectionHeading = ({ title, markdown }) => (
  <div className={styles.wrapper}>
    <Heading
      level={2}
      size="large"
      spacing="0 0 medium 0"
    >
      {title}
    </Heading>
    <div
      className={styles.markdown}
      dangerouslySetInnerHTML={{ __html: markdown }}
    />
  </div>
);

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
};

export default SectionHeading;
