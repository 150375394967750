import React from 'react';
import PropTypes from 'prop-types';

import BlogPost from '../BlogPost';
import styles from './styles.module.scss';

function BlogPostsGrid({ posts }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.postsWrapper}>
        {posts.map(({ node: post }) => (
          <BlogPost
            author={post.author.name}
            image={`https://miro.medium.com/v2/resize:fill:400:300/${post.virtuals.previewImage.imageId}`}
            date={post.createdAt}
            title={post.title}
            key={post.id}
            link={`https://medium.com/deliveroo-design/${post.uniqueSlug}`}
          />
        ))}
      </div>
    </div>
  );
}

BlogPostsGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  posts: PropTypes.any.isRequired,
};

export default BlogPostsGrid;
