import React from 'react';
import PropTypes from 'prop-types';
import Button from '@deliveroo/consumer-component-library/components/Button';
import Typed from 'react-typed';

import CenteredContainer from '../CenteredContainer';
import logo from './images/logo.svg';
import styles from './styles.module.scss';

function getLongestTitle(titles) {
  return [...titles].sort(
    (a, b) => b.length - a.length || a.localeCompare(b),
  )[0];
}

class Header extends React.Component {
  renderTyped() {
    return (
      <Typed
        strings={this.props.titles}
        typeSpeed={50}
        backSpeed={50}
        backDelay={2000}
        smartBackspace
        autoInsertCss={false}
        loop
      />
    );
  }

  render() {
    const { additionalMarkdown } = this.props;
    const { titles } = this.props;
    const longestTitle = getLongestTitle(titles);

    return (
      <div className={styles.header}>
        <CenteredContainer>
          <div className={styles.content}>

            <img
              className={styles.logo}
              src={logo}
              alt="Deliveroo Experience team"
            />

            <h1 className={styles.title}>
              <span className={styles.transparentTitle} aria-hidden>
                {longestTitle}
              </span>
              <span className={styles.visibleTitle}>{this.renderTyped()}</span>
            </h1>
            <div
              className={styles.markdown}
              dangerouslySetInnerHTML={{ __html: additionalMarkdown }}
            />
            <div className={styles.restyledButton}>
              <Button
                spacing="large 0 0 0"
                href="#jobs"
              >
                See open roles
              </Button>
            </div>
          </div>
        </CenteredContainer>
      </div>
    );
  }
}

Header.propTypes = {
  additionalMarkdown: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
