import React from 'react';
import PropTypes from 'prop-types';
import Span from '@deliveroo/consumer-component-library/components/Span';
import Button from '@deliveroo/consumer-component-library/components/Button';

import styles from './styles.module.scss';

const JobRow = ({ role, location, link }) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <Span isBold isBlock>
        {role}
      </Span>
      <Span spacing="small 0 0 0" color="grey">
        {location}
      </Span>
    </div>
    <div className={styles.secondaryButtonWrapper}>
      <Button href={link} newWindow size="small" type="secondary">
        Read more
      </Button>
    </div>
  </div>
);

JobRow.propTypes = {
  role: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default JobRow;
