import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import PersonCard from '../PersonCard';

class PeopleGrid extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className={styles.grid}>
          {React.Children.map(this.props.children, (card, index) => {
            return <div className={styles.card}>{card}</div>;
          })}
          <div id="#jobs" className={styles.card}>
            <PersonCard name="You?" role="" image="" />
          </div>
        </div>
      </div>
    );
  }
}

PeopleGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PeopleGrid;