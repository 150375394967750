import React from 'react';
import PropTypes from 'prop-types';
import Span from '@deliveroo/consumer-component-library/components/Span';
import BackgroundImage from '@deliveroo/consumer-component-library/components/BackgroundImage';
import styles from './styles.module.scss';
import placeholderImage from '/content/people/images/placeholder.png';

const PersonCard = ({ name, role, image }) => (
  <div className={styles.person}>
    <div className={styles.imageContainer}>
      <div className={styles.image}>
        <BackgroundImage placeholderSrc={placeholderImage} src={image} />
      </div>
      <div className={styles.imageBorder}>
      </div>
    </div>
    <div className={styles.content}>
      <h4>
        <Span isBold>{name}</Span>
      </h4>
      <Span size="small" spacing="small 0 0 0" isBlock color="grey">
        {role}
      </Span>
    </div>
  </div>
);

PersonCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default PersonCard;
