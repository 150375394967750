import React from 'react';
import Span from '@deliveroo/consumer-component-library/components/Span';
import Button from '@deliveroo/consumer-component-library/components/Button';

import CenteredContainer from '../CenteredContainer';
import styles from './styles.module.scss';

const Footer = () => (
  <div className={styles.container}>
    <CenteredContainer>
      <div className={styles.content}>

        <div className={styles.footerLeading}>
          <Span color="grey">
            <span role="img" aria-label="copyright">
              &copy;
            </span>{' '}
            {new Date().getFullYear()} Deliveroo
          </Span>
        </div>

        <div className={styles.footerTrailing}>
          <a href="https://www.deliveroo.co.uk" target="_blank" rel="noreferrer noopener">Deliveroo home</a>  ·  <a href="https://twitter.com/deliveroodesign" target="_blank" rel="noreferrer noopener">Medium blog</a>  ·  <a href="https://twitter.com/deliveroodesign" target="_blank" rel="noreferrer noopener">@DeliverooDesign</a>
        </div>

      </div>

    </CenteredContainer>
  </div>
);

export default Footer;
