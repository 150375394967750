import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Section = ({ background, children, id }) => (
  <div className={classnames(styles.wrapper, styles[background])} id={id}>
    {children}
  </div>
);

Section.propTypes = {
  background: PropTypes.oneOf(['transparent', 'white']),
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Section.defaultProps = {
  background: 'transparent',
  id: null,
};

export default Section;
